import React from 'react';
import { graphql } from 'gatsby';
import { withLang } from '../../utils/reactTools';
import { Home } from '..';

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/pages/home.md/" }) {
      fields {
        locales {
          fr {
            pageTheme
            ...SeoFragmentFR
            ...heroBannerFragmentFR
            heroVideoUrl
            heroVideoTitle
            caseStudiesHeading
            caseStudies
            caseStudiesGridCtaLabel
            caseStudiesGridCtaUrl
            ...fancyTextFragmentFR
            ...imageTestimonialFragmentFR
            ...ctaBannerFragmentFR
            ...homeStoriesCarouselFragmentFR
          }
        }
      }
    }
    allCaseStudies: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "case-study" } } }
    ) {
      nodes {
        fields {
          locales {
            fr {
              title
              client
              weight
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 2716
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
          }
        }
      }
    }
    allStories: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "story" } } }
    ) {
      nodes {
        fields {
          locales {
            fr {
              title
              weight
              showInListing
              category
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 878 
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
          }
        }
      }
    }
  }
`;

export default withLang(Home, 'fr');
